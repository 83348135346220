.box-layout {
    align-items: center;
    background: url('../images/bg.jpg');
    background-size: cover;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
}

.box-layout__box {
    background: fade-out(white, .15 );
    border-radius: 3px;
    text-align: center;
    width: 25rem;
    padding: $l-size $m-size;
}
.box-layout__title {
    line-height: 1;
    margin: 0 0 $m-size;
}