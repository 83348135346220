.text-input{
    border: 1px solid #cacccd;
    height: 50px;
    padding: $s-size;
    font-size: $font-size-large;
    font-weight: 300;
}
.select__label{
    font-weight: 300;
    font-size: $font-size-large;
}

.select{
    @extend .text-input;
}

.textarea{
    @extend .text-input;
    height: 10rem;
}