.header{
    background: $dark-blue;
}

.header__title{
    color:white;
    text-decoration: none;
    h1{
        margin: 0;
    }
}
.header__content{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $s-size 0;
}