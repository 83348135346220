.page-header{
 background: $off-white;
 padding: $l-size 0;
 margin-bottom: $l-size;
}

.page-header__title {
    margin: 0;
    font-weight: 300;
    span {
        font-weight: bold;
    }
}
.page-header__actions{
    margin-top: $m-size;
}