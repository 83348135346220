* {
    box-sizing: border-box;
}
html {
    font-size: 62.5%; //to make one rem equals 10px

}

body {
    background: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: $m-size;
    color: $dark-grey;
    line-height: 1.6;

}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.is-active {
    font-weight: 700;
}