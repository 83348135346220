.button { 
    background: $blue;
    border: none;
    color: white;
    display: flex;
    font-size: $font-size-large;
    font-weight: 300;
    margin: 0 auto;
    padding: $s-size;
    text-align: center;
    transition: background .5s;
}

.button--action{
    display: inline-block;
    text-decoration: none;
    line-height: 1;
    
}
.button--action:hover{
    background: lighten($blue, 10%);
    transition: background .5s;
}
.button--remove{
  @extend .button--action;
  background: $orange;
  transition: background .5s;
}
.button--remove:hover{
    transition: background .5s;
    background: lighten($orange, 10%);
}

.button--link{
    background: none;
    margin: 0;
}

