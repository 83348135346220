.list-header {
    background: $off-white;
    border: 1px solid darken($off-white, 7%);
    color: $grey;
    display: flex;
    justify-content: space-between;
    padding: $s-size $m-size;
}

.list-item {

    border: 1px solid darken($off-white, 7%);
    border-top: none;
    color: $dark-grey;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: background .3s ease;
    padding: $s-size;
    &:hover {
        background: $off-white;
    }

    @media (min-width:$desktop-breakpoint) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: $m-size;
    }
}

.list-item__body {
    margin-bottom: $m-size;

    @media (min-width:$desktop-breakpoint) {
        margin-bottom: $l-size;
    }

}

.list-item__title {
    margin: 0;
    width: 18rem;
}

.list-item__subtitle {
    color: $grey;
    font-size: 1.4rem;
}

.list-item--message {
    align-items: center;
    justify-content: center;
    color: $grey;
    padding: $m-size;

    &:hover {
        background: none;
    }
}

.list-item__note{
    @extend .list-item__subtitle;
    font-style: italic;
    color: black
}
.list-item__data {
    margin: $s-size 0 0 0;
    @media (min-width:$desktop-breakpoint) {
        margin: 0;
        padding-left: $s-size;
    }
}